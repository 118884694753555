import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation'
import './Project.css'

function Project({ project }) {

    const [ind, setInd] = useState(0);
    const swiperRef = useRef(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (project.length > 0 && ind >= project.length) {
            setInd(0);
        }

        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [project]);

    const setSlide = (index) => {
        setInd(index);
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const renderProject = (item, index) => (
        <SwiperSlide key={index}>
            <div className="row px-5 py-5 Hover">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" height="200px" alt="" style={{ borderRadius: '10px' }} />
                </div>
                <div className="col-12 mt-5 text-center" style={{ color: "#021633", fontSize: 'var(--Regular)' }}>
                    <strong>
                        {item.Title}
                    </strong>
                </div>
            </div>
        </SwiperSlide>
    )

    return (
        <div className='row'>

            <Swiper
                className='mb-5'
                ref={swiperRef}
                spaceBetween={30}
                slidesPerView={3} // Display 3 slides per view
                slidesPerGroup={1} // Move one slide at a time when navigating
                loop={true} // Enable loop so you can continuously navigate slides
                modules={[Pagination, Autoplay, Navigation]}
                pagination={{ clickable: true }}
                navigation
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                onSlideChange={(swiper) => setSlide(swiper.activeIndex)}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                }}
            >
                {project && project.map(renderProject)}
            </Swiper>


            {loading && (
                <div className="col-12">
                    Loading...
                </div>
            )}

            {!loading && project[ind] && (
                <div className="col-12 mt-5">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-12 text-center" style={{ color: 'var(--primary)', fontSize: 'var(--subTitle)' }}>
                            <strong>
                                {project[ind].Title}
                            </strong>
                        </div>
                        <div className="col-12 mt-5 d-flex align-items-center justify-content-center">
                            <img src={`${process.env.REACT_APP_API_URL}${project[ind].Image}`} width="100%" alt="" style={{ borderRadius: '20px', boxShadow: "0px 10px 10px 0px rgba(0,0,0,0.1)" }} />
                        </div>
                        <div className="col-12 Margin py-5 w-75" style={{ backgroundColor: 'rgba(255,255,255, 0.9)', fontSize: 'var(--Regular)', borderRadius: '15px', boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.5)' }}>
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <div className="row">
                                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                                            SCOPE OF WORK:
                                        </div>
                                        <div className="col-12" style={{ color: 'var(--grey)' }}>
                                            <ul>
                                                {project[ind].Description.map((item, index) => (
                                                    <li dangerouslySetInnerHTML={{ __html: item.Scope }} key={index}></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4" style={{ color: 'var(--grey)' }}>
                                    <div className="row">
                                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                                            CATEGORY:
                                        </div>
                                        <div className="col-12">
                                            {project[ind].Category}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 " style={{ color: 'var(--primary)' }}>
                                            CLIENT:
                                        </div>
                                        <div className="col-12">
                                            {project[ind].Client}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 " style={{ color: 'var(--primary)' }}>
                                            YEAR:
                                        </div>
                                        <div className="col-12">
                                            {project[ind].Year}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="col-12 my-5">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-auto Hover" onClick={goPrev} style={{
                        transform: 'rotate(180deg)'
                    }}>
                        <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.5125 12.4536C0.878944 12.5493 0.390625 13.154 0.390625 13.8856C0.390625 14.6837 0.971765 15.3307 1.68864 15.3307L24.5047 15.3307L16.2627 24.4701L16.1367 24.6319C15.7586 25.1968 15.799 25.9997 16.2589 26.5138C16.7647 27.0793 17.5866 27.0812 18.0945 26.5181L28.5465 14.9294C28.6098 14.8619 28.667 14.7874 28.717 14.7069C29.0682 14.1428 29.0176 13.3624 28.5651 12.8607L18.0945 1.25341L17.9486 1.11384C17.4396 0.695284 16.7186 0.743656 16.2588 1.25781C15.753 1.82339 15.7547 2.7384 16.2627 3.30154L24.5068 12.4404L1.68864 12.4404L1.5125 12.4536Z" fill="#1B7A99" />
                        </svg>
                    </div>
                    <div className="col-auto Hover" onClick={goNext}>
                        <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.5125 12.4536C0.878944 12.5493 0.390625 13.154 0.390625 13.8856C0.390625 14.6837 0.971765 15.3307 1.68864 15.3307L24.5047 15.3307L16.2627 24.4701L16.1367 24.6319C15.7586 25.1968 15.799 25.9997 16.2589 26.5138C16.7647 27.0793 17.5866 27.0812 18.0945 26.5181L28.5465 14.9294C28.6098 14.8619 28.667 14.7874 28.717 14.7069C29.0682 14.1428 29.0176 13.3624 28.5651 12.8607L18.0945 1.25341L17.9486 1.11384C17.4396 0.695284 16.7186 0.743656 16.2588 1.25781C15.753 1.82339 15.7547 2.7384 16.2627 3.30154L24.5068 12.4404L1.68864 12.4404L1.5125 12.4536Z" fill="#1B7A99" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Project;
