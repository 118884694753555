import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { RequestManager } from '../Requests/RequestManager';
import TransformBusiness from '../Transformer/TransformBusiness';
import Background from './Media/Layer_1.png';
import './ProjectSlider.css';

function Projects() {
    const customOrder = [
        "ALBA PS5 1800 MW COMBINED CYCLE POWER PLANT",
        "ALBA POWER STATION 4",
        "INTEGRATED POWER & WATER PLANT (5x95 MW) CCPP, HRSG",
        "INTEGRATED POWER & WATER PLANT (5x95 MW) CCPP, ACC",
        "ATINKOU 400 MW COMBINED CYCLE POWER PLANT CIPREL 5",
        "ATINKOU 400 MW POWER PLANT CIPREL 5",
        "BAPCO MODERNIZATION PROGRAM",
        "EPC TANK WORKS AND MEI WORKS Lithuania",
        "DRY BULK HANDLING SYSTEM",
        "RIYADH METRO STATION",
        "ARAMCO AJYAL PROJECT",
        "ARAMCO JUBAIL BULK PLANT PROJECT",
        "ARAMCO YASREF",
        "DUQM REFINERY PROJECT - EPC 3 CATRAS MARKAZ",
        "DUQM REFINERY PROJECT - EPC 3 THE LBW AREA",
        "DUQM REFINERY PROJECT - EPC 3 DEEP PITS RFQ 81",
        "SAIPEM FPSO BALEINE MANPOWER SERVICES",
        "MARASSI MALL STEEL STRUCTURE FABRICATION WORKS",
        "RAS AL KHAIR",
        "INSTALLATION WORKS CONTRACT PORT OF DUQM",
        "IRRIGATION TANKS RC 712-C05",
        "FIRE FIGHTING SYSTEM UPGRADE Sudan",
        "ATBARA SAKR POWER PLANT - AL SHAMAL CEMENT FACTORY",
        "PORT SUDAN STRATEGIC STORAGE DEPOT",
        "HADIDA BALILA PIPELINE",
        "ENCOSTEEL WORKSHOP",
        "I AM GOLD BURKINA FASO - LFO TANKS",
        "MALI 2027 KAYES IPP POWER PLANT",
        "MISCELLANEOUS WORKS Duqm",
        "OIL TANKS 37, 38, 39 & 40",
        "4 No. FUEL STORAGE TANKS + WEIGH BRIDGE",
        "TANK R2 & R4 & R6 INSPECTION & REHABILITATION",
        "NAAMEH BIOGAS POWER PLANT (Power Plant for SAKR Power Project)",
        "ZOUK POWER PLANT",
        "JIYEH POWER PLANT",
        "SUPPLY OF SEPS & UTILITY TRAILERS",
    ];
    const [project, setProject] = useState([]);
    const [loading, setLoading] = useState(true);

    const normalizeTitle = (title) => title.trim().replace(/\s+/g, ' ').toUpperCase();

    useEffect(() => {
        const init = async () => {
            try {
                
                const res = await RequestManager.fetchProjects();

                let projects = TransformBusiness(res?.data ?? []);

                projects = projects.sort((a, b) => {
                    const normalizedA = normalizeTitle(a.Title);
                    const normalizedB = normalizeTitle(b.Title);
                    const indexA = customOrder.indexOf(normalizedA);
                    const indexB = customOrder.indexOf(normalizedB);
                
                  
                    if (indexA === -1) return 1; // Place items not in customOrder at the end
                    if (indexB === -1) return -1;
                    return indexA - indexB;
                });

            

                setProject(projects);
            } catch (error) {
                console.error("Error in fetching projects: ", error);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, []);

    const renderProject = (item, index) => (
        <SwiperSlide key={index}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <img
                        src={`${process.env.REACT_APP_API_URL}${item.Image}`}
                        width="auto"
                        height="400px"
                        style={{ borderRadius: '15px' }}
                        alt={item.Title || "Project image"}
                    />
                </div>
                <div className="col-12 col-lg-6 d-flex align-items-center" style={{ fontSize: 'var(--Regular)' }}>
                    <div className="row Slider_Hover">
                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                            <strong>{item.Client}</strong>
                        </div>
                        <div className="col-12" style={{ color: 'var(--primary)' }}>
                            PROJECT: {item.Title}
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    );

    return (
        <div className="col-12">
            {loading ? (
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        Loading...
                    </div>
                </div>
            ) : (
                <div className="row py-5 d-flex flex-lg-row flex-column-reverse" style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: '60%',
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className='col-lg-10 col-12 py-5' style={{ backgroundColor: 'rgba(247, 247, 247, 0.9)' }}>
                        <Swiper
                            spaceBetween={50}
                            modules={[Navigation]}
                            navigation
                        >
                            {project.map(renderProject)}
                        </Swiper>
                    </div>
                    <div className="col-12 col-lg-2 d-flex align-items-center justify-content-center" style={{
                        color: 'var(--primary)',
                        fontSize: '29px',
                        textTransform: 'uppercase'
                    }}>
                        <strong>
                            what did we<br /> work on?
                        </strong>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Projects;
