import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import Background from '../Home/Media/Layer_2.png'
const SingleBusiness = () => {
    const { SingleBusinessId } = useParams();

    const [singleBusiness, setSingleBusiness] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchSingleBusiness(SingleBusinessId);
               
                setSingleBusiness(TransformHome(res?.data) ?? {});
                setLoading(false);
            } catch (err) {
                console.error("Error in fetching Data", err);
            }
        }
        init();
    }, [SingleBusinessId])

    return (
        <div className='container' style={{ maxWidth: '90vw' }}>
            {loading ?
                (
                    <div className="row">
                        Loading ...
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12" style={{
                            fontSize: 'var(--Title2)',
                            color: 'var(--primary)',
                            backgroundImage: `url(${Background})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain'

                        }}>
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <strong>

                                        {singleBusiness.Title}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-5">
                            <img src={`${process.env.REACT_APP_API_URL}${singleBusiness.Image}`} width="100%" height="auto" style={{ borderRadius: '10px' }} alt="" />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: singleBusiness.Description }} className="col-12 text-center my-5" style={{ fontSize: 'var(--Regular)', color: 'var(--grey)' }}>

                        </div>
                    </div>
                )}
        </div>
    );
};

export default SingleBusiness;
