import React from 'react'
import Logo from '../Navbar/Media/Logo'

const Quick_Links = [
    {
        Title: 'HOME',
        Link: '/',
    },
    {
        Title: 'ABOUT US',
        Link: '/about-us'
    },
    {
        Title: 'OUR SERVICES',
        Link: '/services'
    },
    {
        Title: 'PROJECTS',
        Link: '/projects'
    },
    
]

const Company = [
    {
        Title: 'PRIVACY POLICY',
        Link: '/privacy-policy',
    },
    {
        Title: 'DISCLAIMERS',
        Link: '/disclaimers'
    },
    {
        Title: 'TERMS & CONDITIONS',
        Link: '/terms-and-conditions'
    },
    {
        Title: 'CAREERS',
        Link: '/careers'
    },
    {
        Title: 'CONTACT US',
        Link: '/contact-us'
    }
]

const renderLink = (item, index) => (
    <div key={index} className="col-auto Hover">
        {item.Title}
    </div>
)


function Footer() {
    return (
        <div className='container-fluid py-5' style={{ backgroundColor: '#F7F7F7' }}>
            <div className="row">
                <div className="col-12">

                    <div className="container" style={{ maxWidth: '1440px' }}>
                        <div className="row" style={{ fontSize: 'var(--Regular)', color: 'var(--grey)' }}>
                            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                                <Logo />
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-4 d-flex align-items-start justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center text-lg-start d-flex flex-column align-content-start">
                                            <strong className='mb-4' style={{ color: 'var(--primary)' }}>
                                                Quick Links
                                            </strong>
                                            {Quick_Links.map(renderLink)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-5 mt-lg-0 col-lg-4 d-flex align-items-start justify-content-center">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center text-lg-start d-flex flex-column align-content-start">
                                            <strong className='mb-4' style={{ color: 'var(--primary)' }}>
                                                Company
                                            </strong>
                                            {Company.map(renderLink)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-lg-start justify-content-center mt-5">
                            <div className="col-lg-10 col-12">
                                <div className="row d-flex align-items-center justify-content-center">


                                    <div className="col-lg col-12 ml-auto d-flex align-items-center justify-content-center" style={{ fontSize: '14px', color: 'var(--primary)' }}>
                                        © All rights reserved for EncoGroup 2024
                                    </div>
                                    <div className="col-auto text-center d-flex align-items-center justify-content-center">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="19.999" r="20" fill="#1B7A99" />
                                            <path d="M27.6657 16.5647C27.4817 15.9488 26.9395 15.4637 26.2511 15.2991C25.0033 15 20 15 20 15C20 15 14.9967 15 13.7489 15.2991C13.0605 15.4638 12.5183 15.9488 12.3343 16.5647C12 17.681 12 20.0101 12 20.0101C12 20.0101 12 22.3392 12.3343 23.4555C12.5183 24.0714 13.0605 24.5363 13.7489 24.7009C14.9967 25 20 25 20 25C20 25 25.0033 25 26.2511 24.7009C26.9395 24.5363 27.4817 24.0714 27.6657 23.4555C28 22.3392 28 20.0101 28 20.0101C28 20.0101 28 17.681 27.6657 16.5647ZM18.3636 22.1248V17.8954L22.5454 20.0102L18.3636 22.1248Z" fill="white" />
                                        </svg>

                                    </div>
                                    <div className="col-auto text-center">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="19.999" r="20" fill="#1B7A99" />
                                            <path d="M27.1498 16.4876C27.1612 16.6407 27.1612 16.7939 27.1612 16.947C27.1612 21.6173 23.4493 26.9985 16.665 26.9985C14.5749 26.9985 12.6333 26.4188 11 25.4126C11.297 25.4454 11.5825 25.4563 11.8909 25.4563C13.6155 25.4563 15.2031 24.8986 16.4708 23.947C14.849 23.9142 13.4898 22.897 13.0216 21.497C13.25 21.5298 13.4784 21.5516 13.7183 21.5516C14.0495 21.5516 14.3807 21.5079 14.6891 21.4314C12.9987 21.1032 11.7309 19.6813 11.7309 17.9642V17.9204C12.222 18.1829 12.7932 18.347 13.3984 18.3688C12.4048 17.7344 11.7538 16.6516 11.7538 15.4266C11.7538 14.7704 11.9365 14.1688 12.2563 13.6438C14.0723 15.7876 16.802 17.1876 19.8629 17.3407C19.8058 17.0782 19.7716 16.8048 19.7716 16.5314C19.7716 14.5845 21.4162 12.9985 23.4607 12.9985C24.5228 12.9985 25.4822 13.4251 26.1561 14.1142C26.9898 13.961 27.7893 13.6657 28.4975 13.261C28.2233 14.0814 27.6409 14.7704 26.8756 15.2079C27.618 15.1314 28.3376 14.9345 29 14.661C28.4975 15.361 27.8693 15.9844 27.1498 16.4876Z" fill="white" />
                                        </svg>

                                    </div>
                                    <div className="col-auto text-center">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="20" cy="19.999" r="20" fill="#1B7A99" />
                                            <path d="M20.0016 16.4091C18.0152 16.4091 16.4129 18.0117 16.4129 19.9985C16.4129 21.9854 18.0152 23.588 20.0016 23.588C21.988 23.588 23.5902 21.9854 23.5902 19.9985C23.5902 18.0117 21.988 16.4091 20.0016 16.4091ZM20.0016 22.3321C18.7179 22.3321 17.6685 21.2856 17.6685 19.9985C17.6685 18.7115 18.7148 17.6649 20.0016 17.6649C21.2883 17.6649 22.3346 18.7115 22.3346 19.9985C22.3346 21.2856 21.2852 22.3321 20.0016 22.3321ZM24.574 16.2623C24.574 16.7278 24.1992 17.0995 23.737 17.0995C23.2716 17.0995 22.8999 16.7246 22.8999 16.2623C22.8999 15.7999 23.2747 15.4251 23.737 15.4251C24.1992 15.4251 24.574 15.7999 24.574 16.2623ZM26.9508 17.112C26.8977 15.9905 26.6416 14.9971 25.8202 14.1786C25.0019 13.3601 24.0087 13.104 22.8875 13.0477C21.7318 12.9821 18.2682 12.9821 17.1125 13.0477C15.9944 13.1008 15.0012 13.357 14.1798 14.1755C13.3584 14.994 13.1054 15.9874 13.0492 17.1089C12.9836 18.2647 12.9836 21.7292 13.0492 22.8851C13.1023 24.0066 13.3584 25 14.1798 25.8185C15.0012 26.6369 15.9913 26.8931 17.1125 26.9493C18.2682 27.0149 21.7318 27.0149 22.8875 26.9493C24.0087 26.8962 25.0019 26.6401 25.8202 25.8185C26.6385 25 26.8946 24.0066 26.9508 22.8851C27.0164 21.7292 27.0164 18.2679 26.9508 17.112ZM25.4579 24.1253C25.2143 24.7376 24.7427 25.2093 24.1274 25.4561C23.206 25.8216 21.0197 25.7372 20.0016 25.7372C18.9834 25.7372 16.794 25.8185 15.8757 25.4561C15.2636 25.2124 14.792 24.7407 14.5452 24.1253C14.1798 23.2037 14.2641 21.0169 14.2641 19.9985C14.2641 18.9801 14.1829 16.7902 14.5452 15.8718C14.7888 15.2595 15.2605 14.7878 15.8757 14.541C16.7971 14.1755 18.9834 14.2598 20.0016 14.2598C21.0197 14.2598 23.2091 14.1786 24.1274 14.541C24.7395 14.7847 25.2112 15.2564 25.4579 15.8718C25.8233 16.7934 25.739 18.9801 25.739 19.9985C25.739 21.0169 25.8233 23.2068 25.4579 24.1253Z" fill="white" />
                                        </svg>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer