import { AxiosInstance } from "../Axios/Axios";



const endpoints = {

    fetchHomePage: `/api/home-page?populate=*`,
    fetchOurBusiness: `/api/our-business?populate=*`,
    fetchBusiness: `/api/businesses?populate=*&sort[0]=id`,
    fetchDealership: `/api/dealerships?populate=*&sort[0]=id`,
    fetchProject: `/api/projects?populate=*&sort[0]=id&pagination[pageSize]=100`,
    fetchBlog: `/api/blogs?populate=*`,
    fetchSustain: `/api/sustainability?populate=*`,
    fetchServices: `/api/services?populate=*`,
    fetchProjects: `/api/projects?populate=*&sort[0]=id&pagination[pageSize]=100`,
    fetchSustainability: `/api/sustains?populate=*`,
    fetchAboutPage: `/api/about-us?populate=*`,
    fetchSectors: `/api/sectors?populate=*&sort[0]=id`,
    fetchDealershipPage: `/api/dealership-page?populate=*`,
    fetchWhereWeWork: `/api/where-we-work?populate=*`,
    fetchCareer: `/api/career?populate=*`,

}

export const RequestManager = {

    async fetchWhereWeWork() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchWhereWeWork);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchContact() {
        try {
            const response = await AxiosInstance.get(`/api/contacts?populate=*`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchCareer() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchCareer);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchDealershipPage() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchDealershipPage);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchServices() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchServices);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchSustain() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSustain);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchSustainability() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSustainability);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchHomePage() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchHomePage);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchSectors() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSectors);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchAboutPage() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchAboutPage);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchOurBusiness() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchOurBusiness);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchBusiness() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBusiness);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchDealership() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchDealership);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchProject(country) {
        try {
            const response = await AxiosInstance.get(`/api/projects?populate=*&sort[0]=id&pagination[pageSize]=100&filters[Region][Country][$eq]=${country}`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchContinent(continent) {
        try {
            const response = await AxiosInstance.get(`/api/projects?populate=*&sort[0]=id&pagination[pageSize]=100&filters[Search][$eq]=${continent}`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchProjects() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchProjects);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchBlog() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBlog);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchSingleBusiness(id) {
        try {
            const response = await AxiosInstance.get(`/api/businesses/${id}?populate=*`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchSingleNews(id) {
        try {
            const response = await AxiosInstance.get(`/api/blogs/${id}?populate=*`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },

    async fetchSingleDealer(id) {
        try {
            const response = await AxiosInstance.get(`/api/dealerships/${id}?populate[Services][populate]=Image&populate[Image][populate]=Image&populate[Gallery][populate]=Image&populate[Videos][populate]=Links&sort[0]=id`);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },


}