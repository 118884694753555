import React, { useEffect, useState } from 'react'
import Logo from './Media/Logo'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import Accordion from 'react-bootstrap/Accordion';



const Nav = [
    {
        Title: "ABOUT US",
        Link: '/about-us'
    },
    {
        Title: "OUR BUSINESS",
        Link: '/our-business',
        Services:
            [
                {
                    Title: "ENGINEERING, PROCUREMENT & CONSTRUCTION",
                    Link: "/our-business/2"
                },
                {

                    Title: "FABRICATION & MANUFACTURING",
                    Link: "/our-business/1"
                },

                {
                    Title: "INSPECTION SERVICES",
                    Link: "/our-business/5"
                },
                {
                    Title: "OPERATION & MAINTENANCE",
                    Link: "/our-business/3"
                },
                {
                    Title: "OFFSHORE SERVICES",
                    Link: "/our-business/6"
                },
                {
                    Title: "DEALERSHIP",
                    Link: "/our-business/4"
                }


            ]
    },
    {
        Title: "DEALERSHIP",
        Link: '/dealership',
        Services:
            [
                {

                    Title: "KEMPPI",
                    Link: "/dealership/1"
                },

                {
                    Title: "PROMOTECH",
                    Link: "/dealership/2"
                },
                {
                    Title: "COLD JET",
                    Link: "/dealership/3"
                },
                {
                    Title: "G.B.C",
                    Link: "/dealership/4"
                },
                {
                    Title: "NDT ITALIANA",
                    Link: "/dealership/5"
                },
                {
                    Title: "TECNOSEAL INDUSTRY",
                    Link: "/dealership/6"
                }



            ]
    },
    {
        Title: "WHERE WE WORK",
        Link: '/where-we-work',
        Services:
            [
                {

                    Title: "MIDDLE EAST",
                    Link: "/where-we-work#me"
                },

                {
                    Title: "GULF",
                    Link: "/where-we-work#gulf"
                },
                {
                    Title: "EUROPE",
                    Link: "/where-we-work#europe"
                },
                {
                    Title: "AFRICA",
                    Link: "/where-we-work#africa"
                },

            ]
    },
    {
        Title: "SUSTAINABILITY",
        Link: '/sustainability'
    },
    {
        Title: "NEWS",
        Link: '/news'
    },
    {
        Title: "CONTACT US",
        Link: '/contact-us'
    }
]

function Navbar() {
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        var mobile = document.getElementById("mobile");
        if (isOpen === true) {
            mobile.classList.add("active");
        }
        else {
            mobile.classList.remove("active");
        }
    }, [isOpen])

    const handleHover = (id) => {
        var link = document.getElementById(id);
        link.classList.add("active");
    }

    const handleLeave = (id) => {
        var link = document.getElementById(id);
        link.classList.remove("active");
    }
    const navigate = useNavigate();
    const renderNavbar = (item, index) => (
        <div key={index} onMouseEnter={() => handleHover(item.Title)} onMouseLeave={() => handleLeave(item.Title)} className="col-auto d-none d-lg-flex align-items-center justify-content-center" style={{ fontSize: '14px', color: 'var(--primary)', cursor: 'pointer' }}>
            <div onClick={() => navigate(`${item.Link}`)} id={item.Title} href={item.Link} className='Nav d-flex align-items-center' style={{ textDecoration: 'none', color: 'inherit' }}>
                {item.Title} &nbsp;
                {item.Services && (
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L7 7L1 0.999999" fill="#1B7A99" />
                        <path d="M13 1L7 7L1 0.999999" stroke="#1B7A99" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}
            </div>
            {item.Services && (
                <div className="row SubNav p-5" style={{
                    position: 'absolute',
                    top: '90px',
                    width: '300px',
                    backgroundColor: 'white',
                    height: 'auto',
                    boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                    borderRadius: '5px'
                }}>
                    {item.Services.map((item, index1) => (
                        <div onClick={() => navigate(`${item.Link}`)} key={index1} className="col-auto my-3 Hover">
                            <strong style={{ fontSize: '10px' }}>


                                {item.Title}

                            </strong>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
    const renderMobile = (item, index) => (
        <Accordion
            key={index}
            flush
            defaultActiveKey={null}
            className="col-auto d-flex d-lg-none align-items-center justify-content-center"
            style={{
                fontSize: 'var(--regular)',
                color: 'var(--primary)',
                cursor: 'pointer',
            }}
        >
            <Accordion.Item>
                <Accordion.Header>
                    <a href={item.Link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.Title}
                    </a>
                </Accordion.Header>
                {item.Services && (
                    <Accordion.Body>
                        {item.Services && item.Services.map((service, index1) => (
                            <div
                                onClick={() => navigate(`${service.Link}`)}
                                key={index1}
                                className="col-auto my-3 Hover d-flex align-items-center justify-content-center"
                            >
                                <strong style={{ fontSize: '10px' }}>
                                    {service.Title}
                                </strong>
                            </div>
                        ))}
                    </Accordion.Body>
                )}
            </Accordion.Item>
        </Accordion>
    );


    return (
        <div className='container py-5' style={{ maxWidth: '90vw' }}>
            <div className="row">
                <div className="col ml-left d-flex align-items-center justify-content-start">
                    <div onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>
                        <Logo />
                    </div>
                </div>
                {Nav.map(renderNavbar)}
                <div className="col-auto d-none d-lg-flex align-items-center justify-content-center">
                    <button onClick={() => navigate("/career")} className='Button'>JOIN US</button>
                </div>
                <div className="col-auto d-flex align-items-center justify-content-center d-lg-none">

                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>


                <div className='row'>
                    <div id='mobile' className="col-12 Mobile active py-5 d-flex d-lg-none align-items-center flex-column justify-content-between">
                        {Nav.map(renderMobile)}
                        <button onClick={() => navigate("/career")} className='Button'>JOIN US</button>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default Navbar