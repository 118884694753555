import React, { useEffect, useState } from 'react';

import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import Button from '../Shared/Button';
import GeneralInfo from './GeneralInfo';
import Business from './Business';
import Dealership from './Dealership';
import Background from './Media/Layer_1.png'
import Background2 from './Media/Layer_2.png'
import Projects from './Projects';
import Blog from './Blog';

function Home() {
    const [home, setHome] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchHomePage();
                setHome(TransformHome(res?.data) ?? {});
            } catch (err) {
                console.error("Error in fetching data: ", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        init();
    }, []);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}

                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="container" style={{ maxWidth: '90vw' }}>
                    <div className="col-12 d-flex align-items-center">
                        <div className="row" style={{}}>
                            <div className="col-12 px-0 HeroHome" style={{ position: 'absolute', zIndex: '-1', top: 150, left: '0', right: '0', backgroundColor: 'black', }}>
                                <video className='HeroHome' autoPlay muted loop style={{ width: '100vw', opacity: '0.6', objectFit: 'cover' }}>
                                    <source src={`${process.env.REACT_APP_API_URL}${home.HeroMedia}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center HeroHome">
                            <div className="col-12 d-flex align-items-center text-white" style={{ fontSize: 'var(--Title)', fontWeight: '' }}>
                                <div dangerouslySetInnerHTML={{ __html: home.HeroTitle }}>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: home.HeroDescription }} className="col-12 d-flex align-items-center text-white" style={{ fontSize: 'var(--Regular)' }}>
                            </div>
                            <div className="col-12 d-flex align-items-center">
                                <Button />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 py-5" style={{ boxShadow: '0px 10px 10px 0px rgba(0,0,0, 0.05' }}>
                        <div className="col-12 text-center" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                            <strong>
                                KNOW MORE ABOUT US
                            </strong>
                        </div>
                        <div className="col-12 mt-5">
                            <GeneralInfo home={home} />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                            <strong>
                                OUR BUSINESS
                            </strong>
                        </div>
                        <div className="col-12">
                            <Business />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 mt-5" style={{
                            backgroundImage: `url(${Background})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right top'
                        }}>
                            <div className="col-12" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                                <strong>
                                    DEALERSHIP
                                </strong>
                            </div>
                            <Dealership />
                            <div className="col-12 mt-5 d-flex align-items-center Hover justify-content-center" style={{ color: 'var(--primary)' }}>
                                <a href="/dealership" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    SEE ALL DEALERSHIPS
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12" style={{ fontSize: 'var(--subTitle)', color: 'var(--primary)' }}>
                            <strong>
                                PROJECTS
                            </strong>
                        </div>
                        <div className="col-12 mt-5">
                            <Projects />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-5 col-12 d-flex align-items-center justify-content-center" style={{
                            backgroundImage: `url(${Background2})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'left center',
                            backgroundRepeat: 'no-repeat',
                            fontSize: 'var(--subTitle)',
                            color: 'var(--primary)'
                        }}>
                            <div className="row">
                                <div className="col-12">
                                    <strong>
                                        WHAT'S <br />
                                    </strong>
                                    <span style={{ fontSize: 'var(--Title)', fontWeight: 'lighter' }}>
                                        NEW
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7  col-12">
                            <Blog />
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
}

export default Home;
