import React, { useEffect, useState, useRef } from 'react';
import { RequestManager } from '../Requests/RequestManager';
import TransformHome from '../Transformer/TransformHome';
import 'swiper/css';
import 'swiper/css/pagination';
import TransformBusiness from '../Transformer/TransformBusiness';
import Project from './Project';


function Where() {
    const [where, setWhere] = useState({});
    const [project, setProject] = useState([]);
    const [selected, setSelected] = useState('GULF');
    const pro = useRef(null);
    const [selectedCountry, setSelectedCountry] = useState("BAHRAIN");
    const [continent, setContinent] = useState("GULF");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState("BAHRAIN");
    const [ind, setInd] = useState(3);
    const pins = [
        { id: 1, title: 'LEBANON', top: '24%', left: '55%', search: 'LEBANON', continent: "MIDDLE EAST" },
        { id: 2, title: 'KSA', top: '42%', left: '57.7%', search: 'KSA', continent: "GULF" },
        { id: 3, title: 'BAHRAIN', top: '34%', left: '61.5%', search: 'BAHRAIN', continent: "GULF" },
        { id: 4, title: 'BURKINA FASO', top: '40.5%', left: '45.6%', search: 'BURKINA FASO', continent: "AFRICA" },
        { id: 5, title: 'OMAN', top: '92%', left: '62.5%', search: 'OMAN', continent: "GULF" },
        { id: 6, title: "COTE D'IVOIRE", top: '110%', left: '41%', search: "COTE D'IVOIRE", continent: "AFRICA" },
        { id: 7, title: 'LITHUANIA', top: '30%', left: '48%', search: 'LITHUANIA', continent: "EUROPE" },
        { id: 8, title: 'DJIBOUTI', top: '115%', left: '57%', search: 'DJIBOUTI', continent: "AFRICA" },
        { id: 9, title: 'SUDAN', top: '42%', left: '52.5%', search: 'SUDAN', continent: "AFRICA" },
    ];
    const pinsMobile = [
        { id: 1, title: 'LEBANON', top: '25%', left: '70%', search: 'LEBANON', continent: "MIDDLE EAST" },
        { id: 2, title: 'KSA', top: '46%', left: '83%', search: 'KSA', continent: "GULF" },
        { id: 3, title: 'BAHRAIN', top: '34%', left: '90%', search: 'BAHRAIN', continent: "GULF" },
        { id: 4, title: 'BURKINA FASO', top: '46%', left: '34%', search: 'BURKINA FASO', continent: "AFRICA" },
        { id: 5, title: 'OMAN', top: '90%', left: '92%', search: 'OMAN', continent: "GULF" },
        { id: 6, title: "COTE D'IVOIRE", top: '100%', left: '12%', search: "COTE D'IVOIRE", continent: "AFRICA" },
        { id: 7, title: 'LITHUANIA', top: '36%', left: '45%', search: 'LITHUANIA', continent: "EUROPE" },
        { id: 8, title: 'DJIBOUTI', top: '105%', left: '77%', search: 'DJIBOUTI', continent: "AFRICA" },
        { id: 9, title: 'SUDAN', top: '45%', left: '58%', search: 'SUDAN', continent: "AFRICA" },
    ];
    const handleHashChange = () => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {

            setSelected(hash.toUpperCase());
        }
    };

    const fetchContinent = async (param) => {
        const res = await RequestManager.fetchContinent(param);
        setProject(TransformBusiness(res?.data) ?? []);

    }

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchWhereWeWork();
                const res2 = await RequestManager.fetchProject(selectedCountry);
                setWhere(TransformHome(res?.data) ?? {});
                setProject(TransformBusiness(res2?.data) ?? []);

            } catch (err) {
                console.error("Error in fetching data: ", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        init();
        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };


    }, [selectedCountry]);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 text-center">
                            {loading && <p>Loading...</p>}
                            {error && <p className="text-danger">Error: {error}</p>}

                        </div>
                    </div>
                </div>
            </div>
            {!loading && !error && (
                <div className="container-fluid px-0">
                    <div className="row bg-dark">
                        <div className="col-12 d-none d-md-flex align-items-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${where.HeroMedia})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                height: '100vh',
                                width: '100%',
                                fontSize: 'var(--Title)',
                                color: 'white',
                            }}>
                            {pins.map(pin => (
                                <div
                                    key={pin.id}
                                    href={pin.link}
                                    id='Pin'
                                    onClick={() => {
                                        setInd(pin.id);
                                        setLocation(pin.search);
                                        setSelectedCountry(pin.title);
                                        setContinent(pin.continent);
                                        setSelected(pin.continent)
                                        pro.current.scrollIntoView({ behavior: 'smooth' });

                                    }}
                                    className="map-pin d-flex justify-content-center"
                                    style={{
                                        top: pin.top,
                                        left: pin.left,
                                        transform: ind === pin.id ? 'translate(-50%, -50%) scale(1.5)' : 'translate(-50%, -50%) scale(1)'
                                    }}
                                    title={`Pin ${pin.id}`}

                                >
                                    <span className='Country text-start' style={{ position: 'absolute', marginLeft: '', color: 'white', width: 'auto', display: 'block' }}>
                                        <strong style={{ whiteSpace: "nowrap" }}>
                                            {pin.title}
                                        </strong>
                                    </span>

                                </div>
                            ))}
                            <div className="container" style={{ maxWidth: '90vw' }}>

                                <div className="row">
                                    <div className="col-12">
                                        <strong>

                                            WHERE  <br /> WE WORK
                                        </strong>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 d-flex d-md-none align-items-center"
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${where.HeroMedia})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                height: '100vh',
                                width: '100%',
                                fontSize: 'var(--Title)',
                                color: 'white',
                            }}>
                            {pinsMobile.map(pin => (
                                <div
                                    key={pin.id}
                                    href={pin.link}
                                    id='Pin'
                                    onClick={() => {
                                        setInd(pin.id);
                                        setLocation(pin.search);
                                        setSelectedCountry(pin.title);
                                        setContinent(pin.continent);
                                        setSelected(pin.continent)
                                        pro.current.scrollIntoView({ behavior: 'smooth' });

                                    }}
                                    className="map-pin d-flex justify-content-center"
                                    style={{
                                        top: pin.top,
                                        left: pin.left,
                                        transform: ind === pin.id ? 'translate(-50%, -50%) scale(1.5)' : 'translate(-50%, -50%) scale(1)'
                                    }}
                                    title={`Pin ${pin.id}`}

                                >
                                    <span className='Country text-start' style={{ position: 'absolute', marginLeft: '', color: 'white', width: 'auto', display: 'block' }}>
                                        <strong style={{ whiteSpace: "nowrap" }}>
                                            {pin.title}
                                        </strong>
                                    </span>

                                </div>
                            ))}
                            <div className="container" style={{ maxWidth: '90vw' }}>

                                <div className="row">
                                    <div className="col-12">
                                        <strong>
                                            WHERE  <br /> WE WORK
                                        </strong>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container" style={{ maxWidth: '90vw' }}>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="row">

                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("GULF");
                                                    fetchContinent("GULF");
                                                }}
                                                    className="col-12 Location Hover" style={{
                                                        backgroundColor: selected === `GULF` ? 'var(--primary)' : 'white',
                                                        color: selected === `GULF` ? 'White' : 'inherit'
                                                    }}>
                                                    <strong>

                                                        GCC
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("EUROPE");
                                                    setContinent("EUROPE");
                                                    fetchContinent("EUROPE");
                                                }} className="col-12 Location Hover" style={{
                                                    backgroundColor: selected === `EUROPE` ? 'var(--primary)' : 'white',
                                                    color: selected === `EUROPE` ? 'White' : 'inherit'
                                                }} >
                                                    <strong>
                                                        EUROPE
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">

                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("AFRICA");
                                                    setContinent("AFRICA");
                                                    fetchContinent("AFRICA");

                                                }}
                                                    className="col-12 Location Hover"
                                                    style={{
                                                        backgroundColor: selected === `AFRICA` ? 'var(--primary)' : 'white',
                                                        color: selected === `AFRICA` ? 'White' : 'inherit'
                                                    }}>
                                                    <strong>
                                                        AFRICA
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3 px-5">
                                            <div className="row">
                                                <div onClick={() => {
                                                    setSelected("ME");
                                                    setContinent("MIDDLE EAST");
                                                    fetchContinent("MIDDLE EAST");

                                                }} className="col-12 Location Hover" style={{
                                                    backgroundColor: selected === `ME` ? 'var(--primary)' : 'white',
                                                    color: selected === `ME` ? 'White' : 'inherit'
                                                }}>
                                                    <strong>
                                                        MIDDLE EAST
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {selected === "ME" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div onClick={() => setSelectedCountry("LEBANON")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'LEBANON' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'LEBANON' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            LEBANON
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selected === "GULF" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">

                                                    <div onClick={() => setSelectedCountry("BAHRAIN")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'BAHRAIN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'BAHRAIN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            BAHRAIN
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("KSA")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'KSA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'KSA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            KSA
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("OMAN")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'OMAN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'OMAN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            OMAN
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selected === "EUROPE" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div onClick={() => setSelectedCountry("LITHUANIA")} className="col-12 col-lg-4 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'LITHUANIA' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'LITHUANIA' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            LITHUANIA

                                                        </strong>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                        {selected === "AFRICA" && (
                                            <div className="col-12 mt-5">
                                                <div className="row d-flex justify-content-center align-items-center">

                                                    <div onClick={() => setSelectedCountry("BURKINA FASO")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "BURKINA FASO" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "BURKINA FASO" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            BURKINA FASO
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("COTE D'IVOIRE")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "COTE D'IVOIRE" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "COTE D'IVOIRE" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            COTE D'IVOIRE
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("DJIBOUTI")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === "DJIBOUTI" ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === "DJIBOUTI" ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            DJIBOUTI
                                                        </strong>
                                                    </div>
                                                    <div onClick={() => setSelectedCountry("SUDAN")} className="col-12 col-lg-3 Hover text-center py-3" style={{
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.1)',
                                                        backgroundColor: selectedCountry === 'SUDAN' ? 'var(--primary)' : 'white',
                                                        color: selectedCountry === 'SUDAN' ? 'white' : 'inherit'
                                                    }}>
                                                        <strong>
                                                            SUDAN
                                                        </strong>
                                                    </div>


                                                </div>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12" ref={pro}>
                                    {project && (

                                        <Project project={project} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Where;
